export const environment = {
  methods: {
    TAP_TO_PHONE: 'tap-to-phone',
    QR_CONTINGENCY: 'qr-contingency',
    QR_SIMPLE: 'qr',
    CYBERSOURCE_CHECKOUT: 'cybersource-checkout',
    CASH: 'cash',
    DEPOSIT_IN_ACCOUNT: 'deposit-in-account',
    PHYSICAL_CARD: 'physical-card',
    PHONE: 'phone',
    BCP_CREDIT_CARD: 'bcp-credit-card',
    BCP_CARD: 'bcp-card',
    BCP_DEBIT_CARD: 'bcp-debit-card',
    YAPE: 'yape',
    CANASTA: 'canasta',
    QH_QHANTUY: 'qh_qhantuy',
  },
  TAP_TO_PHONE: {
    // Transaction
    // Transaction Status
    IS_TRANSACTION_APPROVED: 'is_transaction_approved',
    REASON_TEXT: 'reason_text',
    RESPONSE_CODE: 'response_code',
    RESPONSE_CODE_DESCRIPTION: 'response_code_description',

    // Terminal
    IS_ACTIVE_TERMINAL: 'is_active_terminal',
    IS_NOT_ACTIVE_TERMINAL: 'is_not_active_terminal',
    TERMINAL_DATA: 'terminal_data',

    // Transaction errors
    LECTURA: 'Lectura',
    TIMEOUT: 'timeout',
    ON_COULD_NOT_CONNECT_SERVICE: 'on_could_not_connect_service',
    GPS_IS_NEEDED: 'gps_is_needed',
    // TRANSACTION FAILED REASONS
    PIN_CANCELED: 'pin_cancel',
    CUOTAS: 'Cuotas',
    EXTRAFINANCIAMIENTO: 'Extrafinanciamiento',
    COMERCIO: 'Comercio',
    TARJETA: 'Tarjeta',
    MONEDA: 'Moneda',
    COMPROBANTE: 'Comprobante',
    TRANSACCION: 'Transacción',
    IMPUESTO: 'Impuesto',
    OFFLINE: 'Offline',
    APLICACION: 'Aplicación',
    TRANSACTION_CANCELLED: 'transaction_cancelled',

    // Errores catalogados en "reason_text"
    ERRORS: {
      "no_response": "Error al procesar el pago, intente nuevamente por favor.",
      "CUENTA INVALIDA": "Error al procesar el pago, intente nuevamente por favor.",
      "INTENTE DE NUEVO": "Error al procesar el pago, intente nuevamente por favor.",
      "---": "Error al procesar el pago, intente nuevamente por favor",
      "TRANSACCION INVALIDA": "El pago fue rechazado por su banco.",
      "MONTO INVALIDO": "El pago fue rechazado por su banco debido al monto.",
      "TARJETA PERDIDA": "Error al procesar el pago, solicite otro método de pago.",
      "PIN INVALIDO": "El PIN ingresado es incorrecto, intente nuevamente por favor.",
      "TRANSACCION NO PERMITIDA": "El pago ha sido rechazado por su banco.",
      "SOSPECHA FRAUDE": "Ocurrió un error al procesar su pago, solicite otro método de pago.",
      "MONTO LIMITE EXCEDIDO": "El monto cobrado es superior al permitido por la tarjeta.",
      "TARJETA RESTRIGIDA": "Ocurrió un error al procesar su pago, solicite otro método de pago.",
      "BAJO MONTO MINIMO PERMITIDO (TERMINAL)": "El monto mínimo para cobrar es de Bs. 1.00",
      "No description": "Ocurrió un error al procesar su pago. Intente nuevamente más tarde.",
      "TIME OUT": "Ocurrió un error al procesar su pago. Intente nuevamente más tarde.",
      "TRANSACCION EXPIRO": "Ocurrió un error al procesar su pago. Intente nuevamente más tarde.",
      "UNREACHABLE HOST": "Ocurrió un error al procesar su pago. Intente nuevamente más tarde.",
      "DENEGADA": "El pago ha sido rechazado. Reintente con otro medio de pago.",
      "FONDOS INSUFICIENTES": "Fondos Insuficientes. Intente con otro medio de pago.",
      "FONDOS INSUFICIENTES O CVV2 INV.LIDO": "Fondos Insuficientes. Intente con otro medio de pago.",
      "TARJETA VENCIDA (EMISOR)": "La tarjeta que está utilizando está vencida. Reintente con otro medio de pago.",
      "EMISOR INVALIDO": "Emisor de tarjeta inválido.",
    } as { [key: string]: string }
  },
  NFC: {
    NFC_NOT_FOUND: 'nfc_not_found',
    ACTIVE: 'on',
    INACTIVE: 'off',
  },
};
