import {Injectable} from '@angular/core';
import OneSignal from 'onesignal-cordova-plugin';
import { NavController, Platform, ModalController } from "@ionic/angular";
import { SettingsProvider } from './settings';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { ModalSuccessComponent } from '../modals/success/success.component';
import { DeviceState } from 'onesignal-cordova-plugin/dist/Subscription';
import { Plugins } from '@capacitor/core';
import { XtrasService } from './xtras.service';
import { OpenedEvent } from 'onesignal-cordova-plugin/dist/models/NotificationOpened';
import NotificationReceivedEvent from 'onesignal-cordova-plugin/dist/NotificationReceivedEvent';
import { PluginService } from './plugin.service';
const { NativeSettings, LocalNotifications } = Plugins;

@Injectable({
    providedIn: 'root'
})
export class MyOneSignalService {


    private initialized: boolean = false;

    private WebPushSDK: any = {
        loaded: false,
        src: 'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
    };

    constructor (
        private platform: Platform,
        private navCtrl: NavController,
        private authService: AuthService,
        private modalCtrl: ModalController,
        private xServices: XtrasService,
        private pluginService: PluginService,
    ) {
    }

    async init() {

        if (!this.platform.is('capacitor')) {
			// **ONE SIGNAL WEB NO GENERA EL PLAYER_ID ASÍ QUE NO SE PUEDE ENVIAR NOTIFICACIONES A USUARIOS ESPECIFICOS**
            return;
        }

        return this._initCordova();
    }

    private _initCordova() {
        return new Promise((resolve, reject) => {
            if (this.initialized === true) {
                return resolve(this);
            }

            this.platform.ready().then(() => {
                console.log('ONESIGNAL_APP_ID:',SettingsProvider.ONESIGNAL_APP_ID);
                let $this = this;

                OneSignal.setAppId(SettingsProvider.ONESIGNAL_APP_ID);

                OneSignal.getDeviceState((response: DeviceState) => {
                    console.log('ONESIGNAL_DEVICE_STATE:', response);
                    if(!response.hasNotificationPermission) {
                        $this.pluginService.pluginConnect('others', 'toastNative', { message: 'Es necesario conceder el permiso de notificaciones' }).then().catch();
                    }
                });
                

                OneSignal.promptForPushNotificationsWithUserResponse(async (data) => {
                    console.log("Did user accept notifications? " + data);
                    if(data) {
                        OneSignal.getDeviceState(async (response: DeviceState) => {
                            console.log('ONESIGNAL_DEVICE_STATE Ready:', response);
                            if(response.userId) {
                                await $this.authService.setPushTokenNotification(response.userId, false);
                            }
                        });
                    } else {
                        await this.openAppSettings();
                    }
                });

                OneSignal.setNotificationOpenedHandler((data: OpenedEvent) => {
                    console.log('ONESIGNAL_NOTIFICATION_OPENED:', data);
                    console.log(data.notification.rawPayload);
                    $this.navCtrl.navigateRoot('/notifications');
                });

                OneSignal.setNotificationWillShowInForegroundHandler((nt: NotificationReceivedEvent) => {
                    console.log('ONESIGNAL_NOTIFICATION_WILL_SHOW_IN_FOREGROUND:', nt);
                    let data = nt.getNotification();
                    if(data.additionalData) {
                        let subdata: any = data.additionalData;
                        if(subdata.title) {
                            switch (subdata.title) {
                                case 'success-payment':
                                    if(subdata.voucher_data) {
                                        $this.navCtrl.navigateRoot('/payment-receipt', { queryParams: {dataTransaction: subdata.voucher_data} });
                                    } else {
                                        $this.successMakePayment(data.body, '/notifications');
                                    }
                                    break;
                                    
                                case 'success-cart':
                                    break;

                                case 'success-payment-physical':
                                    break;
                            
                                case 'home':
                                    $this.navCtrl.navigateRoot('/home');
                                    break;

                                default:
                                    $this.navCtrl.navigateRoot('/notifications');
                                    break;
                            }
                        }
                    }

                    nt.complete();
                });

                
                // OneSignal.initialize(SettingsProvider.ONESIGNAL_APP_ID);

                // OneSignal.Notifications.addEventListener('click', async (e) => {
                //     let clickData = await e.notification;
                //     console.log("Notification Clicked : " + clickData);
                //     $this.navCtrl.navigateRoot('/notifications');
                // });
                
                // OneSignal.Notifications.addEventListener('foregroundWillDisplay', async (e) => {
                //     let data = await e.getNotification();
                //     console.log("Notification foregroundWillDisplayBody: " + data.body);
                //     console.log("Notification foregroundWillDisplayTitle: " + data.title);
                //     console.log("Notification foregroundWillDisplay: " + JSON.stringify(data));
                //     if(data.additionalData) {
                //         let subdata: any = data.additionalData;
                //         if(subdata.title) {
                //             switch (subdata.title) {
                //                 case 'success-payment':
                //                     if(subdata.voucher_data) {
                //                         $this.navCtrl.navigateRoot('/payment-receipt', { queryParams: {dataTransaction: subdata.voucher_data} });
                //                     } else {
                //                         $this.successMakePayment(data.body, '/notifications');
                //                     }
                //                     break;
                                    
                //                 case 'success-cart':
                //                     break;

                //                 case 'success-payment-physical':
                //                     break;
                            
                //                 default:
                //                     $this.navCtrl.navigateForward('/notifications');
                //                     break;
                //             }
                //         }
                //     }
                // });
                
                // OneSignal.Notifications.requestPermission(true).then((success: Boolean) => {
                //     console.log("Notification permission granted " + success);
                //     if(success) {
                //         OneSignal.User.pushSubscription.getIdAsync().then(async data => {
                //             console.log(`getIdAsync: ${data}`);
                //             if (data) {
                //                 await $this.authService.setPushTokenNotification(data, false);
                //             }
                //         }).catch(error => {
                //             console.log(`getIdAsyncError: ${error}`);
                //         });
                //         OneSignal.User.getOnesignalId().then(data => {
                //             console.log('getOnesignalId:',data);
                //         });
                //         OneSignal.User.getExternalId().then(data => {
                //             console.log('getExternalId:',data);
                //         });
                //         OneSignal.User.getTags().then(data => {
                //             console.log('getTags:',data);
                //         });
                //         OneSignal.User.pushSubscription.getTokenAsync().then(data => {
                //             console.log('getTokenAsync:',data);
                //         });
                //     }
                // });

                // OneSignal.InAppMessages.addEventListener('willDisplay', async (e) => {
                //     console.log(e.message);
                // });

                this.initialized = true;

                return resolve(this);
            });
        });
    }

    loadWebPushSDK() {
        return new Promise((resolve, reject) => {
            if (this.WebPushSDK.loaded) {
                resolve(true);
            } else {
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.WebPushSDK.src;

                script.onload = () => {
                    this.WebPushSDK.loaded = true;

                    resolve(true);
                };

                script.onerror = () => {
                    reject(false);
                };

                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }

    obtainUserId(): Observable<string|null> {
        if(this.xServices.isApp()) {
            return new Observable(observer => {
                const getIdAsync = () => {
                    // OneSignal.User.pushSubscription.getIdAsync().then(data => {
                    //     if (!data) {
                    //         getIdAsync();
                    //     } else {
                    //         console.log(`getIdAsync: ${data}`);
                    //         observer.next(data);
                    //         observer.complete();
                    //     }
                    // }).catch(error => {
                    //     console.log(`getIdAsyncError: ${error}`);
                    //     observer.next(null);
                    //     observer.complete();
                    // });
                    OneSignal.getDeviceState((response: DeviceState) => {
                        console.log('ONESIGNAL_DEVICE_STATE Ready:', response);
                        if(!response.userId) {
                            getIdAsync();
                        } else {
                            observer.next(response.userId);
                            observer.complete();
                        }
                    });
                };
                getIdAsync();
            });
        } else {
            return of(null);
        }
    }

    private async successMakePayment(message: string, urlClose?: string) {
        const modal = await this.modalCtrl.create({
            mode: 'ios',
            component: ModalSuccessComponent,
            cssClass: 'big__modal success__modal',
            componentProps: { message: message }
        });
        if(urlClose) {
          modal.onWillDismiss().then(data => {
            // console.log(data);
            setTimeout(() => {
                this.navCtrl.navigateForward(urlClose);
            }, 500);
          });
        }
        await modal.present();
    }

    private async openAppSettings() {
        if (this.xServices.isAppIos()) {
            await NativeSettings['openIOS']({ option: 'app' });
        } else if (this.xServices.isAppAndroid()) {
            await NativeSettings['openAndroid']({ option: 'application_details' });
        }
    }
}
