import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsProvider {

  static ONESIGNAL_APP_ID = environment.onesignalAppId;

  static api_url: string = environment.apiUrl;

  static t2pEnabled: boolean = true; //* NFC Tap To Phone

  static versionAppID: string = '17.1.7'; //* Versión ID APP

  private static endpoints = {
    auth: 'api-auth/authenticate',
    logout: 'api/auth-logout',
  }

  static authLogin(): string{
    return this.api_url + this.endpoints.auth;
  }

  static authLogout(): string{
    return this.api_url + this.endpoints.logout;
  }

  static getUrl(url:string): string{
    return this.api_url + 'device-api/' + url;
  }

  static getUrlNotApi(url:string): string{
    return this.api_url + url;
  }

  static getCustomUrl(url:string): string{
    return url;
  }

  static getOldUrl(url:string): string{
    return this.api_url + 'api/' + url
  }

}
