import { NavController } from '@ionic/angular';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, from, of, switchMap, throwError } from 'rxjs';
import { AuthInterface, AuthUserModel } from '../interfaces/interfaces';
import { SettingsProvider } from './settings';
import { HttpClientProvider } from './http-client';
import { LoadingClientProvider } from './loading-client';
import { LoadingService } from './loading.service';
import { StorageService } from './storage.service';

export type UserType = AuthInterface | null;

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	private _user = new BehaviorSubject<UserType>(null);
  	user$ = this._user.asObservable();
	set setUser(value: UserType) {
		this._user.next(value);
	}

	headers:any = null;

	constructor(
		private httpClient: HttpClient,
		private navCtrl: NavController,
		private load: LoadingClientProvider,
		private loading: LoadingService,
		private http: HttpClientProvider,
		private storage: StorageService,
	) {
	}

	async getToken() {
		let auth: any = await this.storage.get('auth');
		return this._getToken(auth) || null;
	}

	private _getToken(auth:AuthInterface){
		if(auth) {
			return auth.access_token;
		} else {
			return null;
		}
	}

	async updateToken(token: any) {
		let auth:any = await this.storage.get('auth');
		if(auth) {
			const authNew:UserType = { ...auth, access_token: token };
			await this.storage.set('auth', authNew);
			await this.getUserByToken().toPromise();
		} else {
			await this.logout('expired');
		}
	}

	async updateUserStorage(user: AuthInterface['user']) {
		let auth:any = await this.storage.get('auth');
		if(auth) {
			const authNew:UserType = { ...auth, user: user };
			await this.storage.set('auth', authNew);
		} else {
			await this.logout('expired');
		}
	}

	async logout(type?: any) {
		// localStorage.clear();
		if(type&&type == 'expired') {
			await this.logoutData();
			this.load.presentToast('Sesión expirada, debe iniciar sesión nuevamente.');
		} else {
			this.loading.loadingShow('loading_2');
			this.http.postRequest(SettingsProvider.getUrl('logout'), {}).subscribe( async (res) => {
				this.loading.dismiss();
				await this.logoutData();
			}, async error => {
				this.loading.dismiss();
				console.log(error);
				await this.logoutData();
			});
		}
	}

	async logoutData() {
		await this.dataRemove();
		this.navCtrl.navigateRoot('/auth');
	}

	async dataRemove() {
		await this.storage.clear();
		this.setUser = null;
	}

	async successfullOAuthAuthorization(token: string, expires_in: any, user: any) {
		let expireDate = new Date().getTime() + (1000 * expires_in);
		const auth:AuthInterface = { access_token: token, expires_in: expireDate, user: user };
		await this.storage.set('auth', auth);
	}

	async getAuth(): Promise<UserType> {
		let auth:any = await this.storage.get('auth') ?? null;
		if(auth) {
			let _auth:UserType = auth;
			return _auth;
		} else {
			return null;
		}
	}

	getUserByToken(): Observable<UserType> {
		return from(this.getAuth()).pipe(
			switchMap((auth: UserType) => {
				if (!auth || !auth.access_token) {
					this.setUser = null;
					return of(null);
				}

				// * Si se quiere retornar según storage al user
				// this.setUser = auth;
				// return of(auth);

				// * Si se quiere retornar según api (post login / cada que la app inicia por primera vez)
				return this._getUserByToken(auth.access_token).pipe(
					switchMap((data: any) => {
						if(data['status']) {
							let itemData = {
								... data['items'],
								qr_bank: data['qr_bank'],
								qpos_permissions: data['qpos_permissions'],
								feedback: data['feedback'],
								view_mode: data['view_mode'],
								contact: data['contact'],
								delete_account: data['show_logout'],
								qr_contingency: data['qr_contingency'],
								custom_payment_method: data['custom_payment_method']
							};
							if(data['hasVersion'] != null && data['hasVersion'] == false) {
								this.loading.loadingShowNotLastVersionApp(data['hasVersionTitle'], data['message']);
							}
							let currentUser: UserType = { ...auth, user: itemData};
							this.setUser = currentUser;
							this.updateUserStorage(itemData);
							return from(this.userData(currentUser));
						}
						this.setUser = null;
						return from(this.userData(null));
					}),
				);
			}),
			catchError(err => {
				console.log('errorAuth', err);
				// return from(this.userData(null));
				return throwError('Error en la api');
			})
		);
	}

	_getUserByToken(token: string): Observable<AuthUserModel> {
		const httpHeaders = new HttpHeaders({
			Authorization: `Bearer ${token}`,
        });
		let deviceData = {
			'version': SettingsProvider.versionAppID,
			'versionApp': localStorage.getItem('versionApp') ?? '',
			'versionSDK': localStorage.getItem('versionSDK') ?? '',
			'typeDevice': localStorage.getItem('typeDevice') ?? '',
		};
		let queryParams = new HttpParams({ fromObject: deviceData });
		return this.httpClient.get<AuthUserModel>(SettingsProvider.getUrl('company'), {
            headers: httpHeaders,
			params: queryParams,
        });
	}

	async userData(user?: any) {
		if (!user) {
		  	await this.logout();
		}
		return user;
	}

	async setPushTokenNotification(token: string, update:boolean = true) {
		await this.storage.set('pushToken', token);
		if(update) await this.updateUserPushToken(token);
	}
	
	async getPushTokenNotification() {
		let data = await this.storage.get('pushToken');
		return data;
	}

	private async updateUserPushToken(token :any) {
        await this.http.postRequest(SettingsProvider.getUrl('update-user-token'), { token }).toPromise();
    }
}
