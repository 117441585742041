import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { filter } from 'rxjs/operators';
import "@capacitor-community/firebase-analytics";
import { XtrasService } from './xtras.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private router: Router,
    private xServices: XtrasService,
  ) {
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      console.log('route changed: ', event.url);
      this.setScreenName(event.url);
    });
    this.initializeApp();
  }
  
  async initializeApp() {
    if(!this.xServices.isApp()) {
      await FirebaseAnalytics.initializeFirebase({
        apiKey: "AIzaSyD-QRhAndvJLNk0x5jqETPMEs--PbVKT_0",
        authDomain: "qhantuy.firebaseapp.com",
        projectId: "qhantuy",
        storageBucket: "qhantuy.appspot.com",
        messagingSenderId: "476927506104",
        appId: "1:476927506104:web:a033db0829e96d6003264d",
        measurementId: "G-8FE8S2CP5F"
      });
    }
    // FirebaseAnalytics.enable();
  }

  async setUserId(userId: string) {
    return await FirebaseAnalytics.setUserId({
      userId: userId,
    });
  }

  async setUserProperty(name: string, value: string) {
    return await FirebaseAnalytics.setUserProperty({
      name: name,
      value: value,
    });
  }

  async setScreenName(screenName: string, nameOverride?: string) {
    return await FirebaseAnalytics.setScreenName({screenName, nameOverride});
  }

  async logEvent(name: string, params?: object) {
    return await FirebaseAnalytics.logEvent({
      name: name,
      params: params ?? {},
    });
  }
}