import { Component } from '@angular/core';
import { IonApp, IonToast, IonRouterOutlet, IonButton } from '@ionic/angular/standalone';
import { Platform, ToastController } from '@ionic/angular';
import { MyOneSignalService } from './services/onesignal.service';
import { register } from 'swiper/element/bundle';
import { AuthService } from './services/auth.service';
import { CommonModule } from '@angular/common';
import { XtrasService } from './services/xtras.service';
import { FooterComponent } from './components/footer/footer.component';
import { StatusData, StatusNFCService } from './services/status-nfc.service';
import { PluginService } from './services/plugin.service';
import { SettingsProvider } from './services/settings';
import { HttpClientProvider } from './services/http-client';
import { environment } from './services/environments';
import { LoadingService } from './services/loading.service';
import { NetworkService } from './services/network.service';
import { KeepAwakeService } from './services/status-keep-awake.service';
import { LoadingClientProvider } from './services/loading-client';
import { AnimationOptions, LottieModule } from 'ngx-lottie';
import { AnalyticsService } from './services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [
    IonButton, 
    IonToast, 
    IonApp,
    IonRouterOutlet,
    CommonModule,
    FooterComponent,
    LottieModule,
  ],
  providers: [
    ToastController
  ]
})
export class AppComponent {

  logged:boolean = false;

  title = 'Qhantuy Empresa App';

  isConnected:boolean = false;
  initTapToPhone = false;
  _statusNFC: StatusData = { status: 'notInitialize' };

  checkSplashScreen:boolean = false;
  showSplashScreen:boolean = true;
  loadingShort:boolean = false;

  optionsSplash: AnimationOptions = {
    path: 'assets/animations/loader-white.json',
    loop: true,
  };
  
  constructor(
    public platform: Platform,
    private oneSignalService: MyOneSignalService,
    public authService: AuthService,
    public xServices: XtrasService,
    private pluginService: PluginService,
    private http: HttpClientProvider,
    private loading: LoadingService,
    private load: LoadingClientProvider,
    private statusNFCService: StatusNFCService,
    private networkService: NetworkService,
    private keepAwakeService: KeepAwakeService,
    private analyticsService: AnalyticsService,
  ) {
    this.initializeAnalytics();
    authService.getToken().then(data => {
      this.logged = !! data;
    });

    register();
    this.initService();

    this.keepAwakeService.init();
    this.oneSignalService.init();

    this.initSplashScreen();

    authService.user$.subscribe(data => {
      this.logged = !!data;
    });
    this.initializeApp();
  }

  async initializeAnalytics() {
    this.authService.getAuth().then(async (data) => {
      if (data) {
        await this.analyticsService.setUserId(data.user.device_id);
        await this.analyticsService.setUserProperty('Dispositivo', (data.user.device_name ?? 'Dispositivo'));
      } else {
        await this.analyticsService.setUserId('NOT AUTH');
        await this.analyticsService.setUserProperty('Auth', 'not-auth');
      }
    });
  }

  initSplashScreen() {
    if(this.xServices.isApp()) {
      this.checkSplashScreen = false;
      this.xServices.checkVersionAndroid().then(data => {
        this.checkSplashScreen = true;
        if((data && (data['sdkVersion'] && data['sdkVersion'] >= 31) || this.xServices.isAppIos())) {
          this.showSplashScreen = true;
          this.showSplashScreenFunc();
        } else {
          this.showSplashScreen = false;
        }
      }).catch(error => {
        this.checkSplashScreen = true;
        this.showSplashScreen = false;
      });
    } else {
      this.checkSplashScreen = true;
      this.showSplashScreen = false;
    }
  }

  showSplashScreenFunc() {
    let ssComplete:boolean = localStorage.getItem('ssComplete') === 'yes';
    this.loadingShort = ssComplete;
    if(!this.loadingShort) {
      localStorage.setItem('ssComplete', 'yes');
    }
    setTimeout(() => {
      this.showSplashScreen = false;
    }, (!this.loadingShort ? 5000 : 1000));
  }

  async initializeApp() {
    if(this.platform.is('capacitor')){

      // this.platform.ready().then(() => {
      //   this.statusBar.backgroundColorByHexString('#ffffff');
      //   this.statusBar.styleDefault();
      //   this.statusBar.isVisible;
      //   this.statusBar.show();
      // });

      if(this.xServices.isAppAndroid()) {
        // Inicializar siempre la impresora
        this.pluginService.pluginConnect('imin', 'onMethodCall', { 'method': 'initPrinter' }).then((response: any) => {
          console.log('IminPlugin', response);
        }).catch((error) => {
          console.error('IminPluginError', error);
        });
        let pluginResp = await this.pluginService.pluginConnect('others','versionDevice');
        if(pluginResp && (pluginResp['versionAndroid'] || pluginResp['versionSDK'])) {
          localStorage.setItem('versionApp', pluginResp['versionAndroid']);
          localStorage.setItem('versionSDK', pluginResp['versionSDK']);
          localStorage.setItem('typeDevice', 'android');
        }
      } else if(this.xServices.isAppIos()) {
        localStorage.setItem('typeDevice', 'ios');
      } else {
        localStorage.setItem('typeDevice', 'web');
      }

    } else {
      let match = navigator.userAgent.match(/(Chrome|Edge|Firefox|Safari)/);
      let browserName = match ? match[0] : 'Unknown';

      match = navigator.userAgent.match(/(Chrome|Edge|Firefox|Safari)\/([\d.]+)/);
      let browserVersion = match && match.length > 2 ? match[2] : 'Unknown';
      localStorage.setItem('versionApp', browserName);
      localStorage.setItem('versionSDK', browserVersion);
      localStorage.setItem('typeDevice', 'web');
    }
    setTimeout(async () => {
      let tokenAuth = await this.authService.getToken();
      if(tokenAuth) {
      
        this.http.getRequest(SettingsProvider.getUrl('validate-session')).subscribe({
          next: async (result) => {
            if(!result['check']) {
              await this.authService.logout('expired');
            } else if(result['token']) {
              await this.authService.updateToken(result['token']);
            }
          },
          error: (err) => {
            console.log(err);
          },
        });
      }
    });

    this.networkService.getNetworkStatus().subscribe((connected: boolean) => {
      this.isConnected = connected;
      if (!this.isConnected) {
        console.log('Por favor enciende tu conexión a Internet');
        this.loading.loadingShowNetwork();
      } else {
        this.loading.dismissLoadingNetwork();
      }
    });
  }

  async initService() {
    if(this.xServices.isAppAndroid() && SettingsProvider.t2pEnabled) {
      this.statusNFCService.setStatusNFCData = { status: 'loading' };

      this.pluginService.pluginConnect('demo', 'runNativeCode', { method: "initService" }).then(async data => {
        if(data) {
          if(data['status']) {
            localStorage.setItem('deviceId', data['deviceId'] ?? '');
            if(data['code'] == environment.TAP_TO_PHONE.IS_ACTIVE_TERMINAL) {
              this.statusNFCService.setStatusNFCData = { status: 'success' };
            } else {
              this.statusNFCService.setStatusNFCData = { status: 'notInitialize' };
            }
          } else {
            localStorage.setItem('deviceId', data['deviceId'] ?? '');
            if(data['code'] == environment.TAP_TO_PHONE.IS_NOT_ACTIVE_TERMINAL) {
              await this.load.presentToast(data['message'] ?? 'Hubo un error al inicializar el método de pago por NFC.');
              this.statusNFCService.setStatusNFCData = { status: 'notInitialize' };
            } else {
              await this.load.presentToast(data['message'] ? `Error: ${data['message']}` : 'Hubo un error al inicializar el método de pago por NFC.');
              this.statusNFCService.setStatusNFCData = { status: 'error' };
            }
          }
        } else {
          localStorage.setItem('deviceId', '');
          this.statusNFCService.setStatusNFCData = { status: 'error' };
        }
      }).catch(err => {
        console.log('initServiceError', err);
        this.statusNFCService.setStatusNFCData = { status: 'error' };
      });
    } else {
      localStorage.setItem('deviceId', '');
      this.statusNFCService.setStatusNFCData = { status: 'incompatible' };
    }
  }
  
}
